import React from 'react';
import './LivePage.css';
import TileList from '../../Components/TileList/TileList';

const LivePage = ({events}) => {

    return (
        <main className="cc-lv-root">
            <div className="cc-lv-video-container">
            <p><strong>Cadence Entertainment</strong> has presented 80 plus professional concerts featuring top hindustani classical and semi classical artists from India.
            These concerts are presented live in front of an audience and simultaneously live streamed in HD quality for world wide audiences from professional studios with 
            mutli camera set up. Coming up next is a rare morning ragas concert <a className="cc-lv-href" href="/shashwati-mandal-philly">Feb 22</a>, featuring Vidushi Shashwati Mandal. This will be a intimate baithak in Ambler, PA. It will be simultaneously live streamed worldwide.                         
            <strong> Scroll below</strong> to view list of upcoming live events. 
            You can also visit our <a className="cc-lv-href" href="https://www.youtube.com/c/CadenceEntertainment">YouTube Channel</a> to view clips of the past concerts.
             You can access live concerts at a discounted price by purchasing the <a className="cc-lv-href" href="/ccc"><strong>Annual Pass</strong></a>.<br/>
            </p>
            <iframe src="https://www.youtube.com/embed/4LGab02Qvzs" title="Vidushi Shashwati Mandal" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
            <div className="cc-lv-main">
            <p className='cc-lv-tile-heading'>Upcoming Events. Click on the poster image for details.</p>
            <TileList list={events} />
            </div>
        </main>
    )
}

export default LivePage;